//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SecondaryButton from '@/components/shared/secondary-button'
import primaryButton from "@/components/shared/primary-button";
import {mapActions} from 'vuex'
import {frontends} from "@/enums/frontends";
export default {
  name: 'ForgotPasswordDialog',
  components: {
    SecondaryButton,
    primaryButton,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      forgotPasswordForm: {
        username: '',
      },
      emailRules: [
        v => !!v || 'This is a required field.',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Please enter a valid email address. ',
      ],
      valid: true,
    }
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    async resetPassword() {
      if (
        this.$refs.forgetPasswordForm &&
        this.$refs.forgetPasswordForm.validate()
      ) {
        try {
          const response = await this.$axios.post('auth/forgotPassword', {
            Username: this.forgotPasswordForm.username,
            customerCategoryCode: "WEBPOS_USERS", // added just to master, will be removed after single sign on
            frontendID: frontends.WEB_POS,
          })

          if (response.status === 200) {
            if (response.data.apiMessages.hasErrors) {
              this.$toast.error(
                response.data.apiMessages.serverErrors.join(' \n')
              )
            } else {
              this.$toast.success(
                response.data.apiMessages.successMessages.join(' \n')
              )
            }
          } else {
            this.$toast.error(
              'There was an error resetting the password for the user! Error code: ' +
                response.status
            )
          }
        } catch (err) {
          this.$toast.error(
            'There was an error resetting the password for the user!'
          )
        }
      }
    },
  },
}
