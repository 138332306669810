//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import ForgotPasswordDialog from '@/components/common/forgot-password-dialog'
import SecondaryButton from "@/components/shared/secondary-button";
import PrimaryButton from "@/components/shared/primary-button";
import {userCategoryCodes} from "@/enums/userCategoryCodes";

export default {
  name: 'LoginCard',
  components: {
    LabeledControlLayout,
    ForgotPasswordDialog,
    SecondaryButton,
    PrimaryButton
  },
  props: {
    logoUrl: {
      type: String,
      default: ''
    },
    loginText: {
      type: String, default: ""
    },
    loginContactPhoneNumber: {
      type: String, default: ""
    },
    loginContactEmailAddress: {
      type: String, default: ""
    },
  },
  data() {
    return {
      forgotPasswordDialog: false,
      createNewCustomer: true,
      signInForm: {
        data: {
          email: '',
          password: '',
        },
        emailRules: [
          (v) => !!v || 'Required',
          (v) => /.+@.+\..+/.test(v) || this.$t('Please enter a valid email address'),
        ],
        rules: {
          required: (value) => !!value || 'Required',
          min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        },
        rememberMe: false,
        showPassword: false,
        valid: false,
      },
    }
  },
  methods: {
    ...mapActions('userLoginsForPinLogin', ['updateSavedLogins','rememberLogin','removeRememberedLogin','getRememberedLogin']),
    ...mapActions('common/stores', ['checkStoresByUsername']),
    async signIn() {
      if (this.$refs.signInForm.validate()) {
        try {
          const checkStores = await this.checkStoresByUsername(this.signInForm.data.email)

          if (!checkStores) {
            this.$toast.error('Invalid username or password!')
            console.warn('There is no store assigned to this user!')
            return;
          }

          const response = await this.$auth.loginWith('local', {
            data: {
              username: this.signInForm.data.email,
              password: this.signInForm.data.password,
              categoryCode: userCategoryCodes.WEBPOS_USERS
            },
          })
          if (response.status === 200 && response.data.token) {
            this.$toast.success('Logged in!')
            this.updateSavedLogins({
              userName: this.signInForm.data.email,
              password: this.signInForm.data.password,
              pin: null,
            })
            if(this.signInForm.rememberMe === true) {
              this.rememberLogin({
                userName: this.signInForm.data.email,
                password: this.signInForm.data.password,
                pin: null,
              })
            }
            else if(this.signInForm.rememberMe === false) {
              this.removeRememberedLogin()
            }
            this.$emit('loggedIn')
          } else {
            this.$toast.error('Invalid username or password!')
          }
        } catch (err) {
          console.log('err', err)
          this.$toast.error('Invalid username or password!')
        }
      }
    },
    signUp() {
      this.$emit('signUpHandler')
    },
    showDialog() {
      this.forgotPasswordDialog = true
    },
    doResetPassword() {
      // todo
    },
    rememberMeChanged() {
      this.signInForm.rememberMe = !this.signInForm.rememberMe;
      if(!this.signInForm.rememberMe) {
        this.removeRememberedLogin();
      }
    }
  },

  mounted() {
    this.getRememberedLogin().then(loginInfo=>{
      if(loginInfo!==undefined && loginInfo !== null) {
        this.signInForm.data.email = loginInfo.userName;
        this.signInForm.data.password = loginInfo.password;
        this.signInForm.rememberMe = true;
      }
    })
  }
}
