import { render, staticRenderFns } from "./pin-login-card.vue?vue&type=template&id=40429e4b&scoped=true&"
import script from "./pin-login-card.vue?vue&type=script&lang=js&"
export * from "./pin-login-card.vue?vue&type=script&lang=js&"
import style0 from "./pin-login-card.vue?vue&type=style&index=0&id=40429e4b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40429e4b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
installComponents(component, {VCard,VCardText,VForm,VImg,VOtpInput})
